import authService from "../components/api-authorization/AuthorizeService";
import axios from "axios";

const AD_LINK_TYPE_API_BASE_URL = "v1/adlinktype";

export class AdLinkTypeServiceProvider {
	async getAdLinkTypes() {
		const token = await authService.getAccessToken();
		return axios.get(AD_LINK_TYPE_API_BASE_URL, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
	}
}
