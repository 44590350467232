import authService from "../components/api-authorization/AuthorizeService";
import axios from "axios";

const AD_LINK_API_BASE_URL = "/v1/adlink";

export class AdLinkServiceProvider {
	async createAdLink(adLink) {
		const token = await authService.getAccessToken();
		const json = JSON.stringify(adLink);
		return axios.post(AD_LINK_API_BASE_URL, json, {
			headers: !token
				? {}
				: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
				  },
		});
	}

	async getCampaignAdLinks(campaignId) {
		const token = await authService.getAccessToken();
		const json = JSON.stringify({
			campaignId: campaignId,
		});
		return axios.post(AD_LINK_API_BASE_URL + "/campaign", json, {
			headers: !token
				? {}
				: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
				  },
		});
	}
}
