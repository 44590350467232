import React, { Component } from "react";

export class Home extends Component {
	static displayName = Home.name;

	render() {
		return (
			<div>
				<h1>Welcome To Purchase Link</h1>
			</div>
		);
	}
}
