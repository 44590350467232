import React, { Component } from "react";
//import copy from "copy-to-clipboard";
import { AdLinkServiceProvider, AdLinkTypeServiceProvider, CampaignServiceProvider } from "../../ServiceProviders";
//import { IoMdCopy, IoIosCopy } from "react-icons/io";

export class EditCampaign extends Component {
	constructor(props) {
		super(props);

		this.AdLinkServiceProvider = new AdLinkServiceProvider();
		this.AdLinkTypeServiceProvider = new AdLinkTypeServiceProvider();
		this.CampaignServiceProvider = new CampaignServiceProvider();

		this.state = {
			// step 2
			adLinks: [],
			adLinkTypes: [],
			id: this.props.match.params.id,
			isEditable: true,
			baseTargetUrl: "",
			baseProductUrl: "",
			campaignStatuses: [],
			campaignStatusId: 1,
			name: "",
		};
		this.changeCampaignBaseProductUrlHandler = this.changeCampaignBaseProductUrlHandler.bind(this);
		this.changeCampaignBaseTargetUrlHandler = this.changeCampaignBaseTargetUrlHandler.bind(this);
		this.changeCampaignNameHandler = this.changeCampaignNameHandler.bind(this);
		//this.copyTextHandler = this.copyTextHandler.bind(this);
	}

	// step 3
	componentDidMount() {
		// step 4
		this.AdLinkTypeServiceProvider.getAdLinkTypes().then((res) => {
			this.setState({
				adLinkTypes: res.data,
			});
		});

		this.CampaignServiceProvider.getCampaignStatuses().then((res) => {
			let campaignStatuses = res.data;
			this.setState({
				campaignStatuses: campaignStatuses,
			});
		});

		if (this.state.id === "_add") {
			this.setState({
				campaignStatusId: 1,
			});
		} else {
			this.AdLinkServiceProvider.getCampaignAdLinks(this.state.id).then((res) => {
				this.setState({
					adLinks: res.data,
				});
			});
			this.CampaignServiceProvider.getCampaignById(this.state.id).then((res) => {
				let campaign = res.data;
				this.setState({
					name: campaign.name,
					baseProductUrl: campaign.baseProductUrl,
					baseTargetUrl: campaign.baseTargetUrl,
					campaignStatusId: campaign.campaignStatusId,
				});
			});
		}
	}

	saveOrUpdateCampaign = (event) => {
		event.preventDefault();
		var campaignId = this.state.id;
		if (this.state.id === "_add") {
			campaignId = 0;
		}
		let campaign = {
			name: this.state.name,
			baseProductUrl: this.state.baseProductUrl,
			baseTargetUrl: this.state.baseTargetUrl,
			campaignStatusId: this.state.campaignStatusId,
			id: campaignId,
		};
		console.log("campaign => " + JSON.stringify(campaign));

		this.CampaignServiceProvider.createOrUpdateCampaign(campaign).then((res) => {
			this.props.history.push(`/campaign/${res.data}`);
			this.setState({
				id: res.data,
			});
			alert("Your campaign has been saved.");
		});
	};

	changeCampaignBaseProductUrlHandler = (event) => {
		this.setState({ baseProductUrl: event.target.value });
	};

	changeCampaignBaseTargetUrlHandler = (event) => {
		this.setState({ baseTargetUrl: event.target.value });
	};

	changeCampaignNameHandler = (event) => {
		this.setState({ name: event.target.value });
	};

	// copyTextHandler = (event) => {
	// 	const textToCopy = event.currentTarget.getAttribute("data-text-to-copy");
	// 	copy(textToCopy);
	// 	event.preventDefault();
	// 	alert("Copied to clipboard");
	// };

	cancel() {
		this.props.history.push("/campaigns");
	}

	getAdLinkTypeButton(adLinkType) {
		const exists = this.state.adLinks.some((adLink) => adLink.adLinkTypeId === adLinkType.id);
		if (exists) {
			const adLink = this.state.adLinks.find((adLink) => adLink.adLinkTypeId === adLinkType.id);
			return (
				<div className="col-8">
					<div className="row">
						<div className="col-6">
							<label>{adLink.shortUrl}</label>
							{/* <button className="btn btn-lg" data-text-to-copy={adLink.shortUrl} onClick={this.copyTextHandler}>
								<IoMdCopy />
							</button> */}
						</div>
						<div className="col-6">
							<label>{adLink.targetUrl}</label>
							{/* <button className="btn btn-lg" data-text-to-copy={adLink.targetUrl} onClick={this.copyTextHandler}>
								<IoMdCopy />
							</button> */}
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<button className="btn btn-info float-right" onClick={this.generateShortLink} data-ad-link-type-id={adLinkType.id} data-ad-link-name={this.state.name + " - " + adLinkType.name}>
					Generate Short Link
				</button>
			);
		}
	}

	getTitle() {
		if (this.state.id === "_add") {
			return <h3 className="text-center">Add Campaign</h3>;
		} else {
			return <h3 className="text-center">Edit Campaign</h3>;
		}
	}

	generateShortLink = (event) => {
		event.preventDefault();
		const adLinkTypeId = event.currentTarget.getAttribute("data-ad-link-type-id");
		const adLinkName = event.currentTarget.getAttribute("data-ad-link-name");
		this.CampaignServiceProvider.generateBitlyLink(this.state.baseProductUrl, this.state.baseTargetUrl, this.state.id, adLinkTypeId).then((res) => {
			var adLink = {
				adLinkTypeId: adLinkTypeId,
				campaignId: this.state.id,
				createdByUserId: res.user.sub,
				name: adLinkName,
				linkStatisticsId: res.result.data.id,
				productUrl: res.product_url,
				shortUrl: res.result.data.link,
				targetUrl: res.target_url,
			};
			var adLinks = this.state.adLinks;
			this.AdLinkServiceProvider.createAdLink(adLink).then((res) => {
				adLinks.push(res.data);
				this.setState({
					adLinks: adLinks,
				});
			});
		});
	};

	render() {
		const isAdd = this.state.id === "_add";
		return (
			<div>
				<br></br>
				<div className="container">
					<div className="row">
						<div className="card col-md-12">
							{this.getTitle()}
							<div className="card-body">
								<form>
									<div className="form-group row">
										<div className="col-4">
											<label>Campaign Name:</label>
										</div>
										<div className="col-8">
											<input type="text" placeholder="Campaign Name" name="name" className="form-control" value={this.state.name} onChange={this.changeCampaignNameHandler} disabled={this.state.isEditable ? "" : "disabled"} />
										</div>
									</div>
									<div className="form-group row">
										<div className="col-4">
											<label>Target Url:</label>
										</div>
										<div className="col-8">
											<input type="text" placeholder="Target Url" name="baseTargetUrl" className="form-control" value={this.state.baseTargetUrl} onChange={this.changeCampaignBaseTargetUrlHandler} disabled={this.state.isEditable ? "" : "disabled"} />
										</div>
									</div>
									<div className="form-group row">
										<div className="col-4">
											<label>Product Url:</label>
										</div>
										<div className="col-8">
											<input type="text" placeholder="Product Url" name="baseProductUrl" className="form-control" value={this.state.baseProductUrl} onChange={this.changeCampaignBaseProductUrlHandler} disabled={this.state.isEditable ? "" : "disabled"} />
										</div>
									</div>
									{!isAdd && (
										<div className="form-group row">
											<div className="col-4">
												<label>Status:</label>
											</div>
											<div className="col-8">
												<select value={this.state.campaignStatusId} onChange={(e) => this.setState({ campaignStatusId: e.target.value })}>
													{this.state.campaignStatuses.map((campaignStatus) => (
														<option key={campaignStatus.id} value={campaignStatus.id}>
															{campaignStatus.displayName}
														</option>
													))}
												</select>
											</div>
										</div>
									)}
									{!isAdd && (
										<div className="form-group">
											<div className="row">
												<div className="col-4"></div>
												<div className="col-4">
													<h4>Short Url</h4>
												</div>
												<div className="col-4">
													<h4>Target Url</h4>
												</div>
											</div>
											{this.state.adLinkTypes.map((adLinkType) => (
												<div className="row" key={adLinkType.id}>
													<div className="col-4">
														<label>{adLinkType.name}:</label>
													</div>
													{this.getAdLinkTypeButton(adLinkType)}
												</div>
											))}
										</div>
									)}

									<button className="btn btn-success" onClick={this.saveOrUpdateCampaign}>
										Save
									</button>

									<button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>
										Cancel
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
