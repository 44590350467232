import React, { Component } from "react";
import { CampaignServiceProvider } from "../../ServiceProviders/CampaignServiceProvider";
import LoadingSpinner from "../shared/LoadingSpinner";

export class ListCampaigns extends Component {
	constructor(props) {
		super(props);

		this.CampaignServiceProvider = new CampaignServiceProvider();

		this.state = {
			campaigns: [],
			loading: true,
		};
		this.addCampaign = this.addCampaign.bind(this);
		this.editCampaign = this.editCampaign.bind(this);
		this.deleteCampaign = this.deleteCampaign.bind(this);
	}

	deleteCampaign(id) {
		var confirm = window.confirm("Do you really want to delete this campaign?");
		if (confirm) {
			this.CampaignServiceProvider.deleteCampaign(id).then((res) => {
				this.setState({
					campaigns: this.state.campaigns.filter((campaign) => campaign.id !== id),
				});
			});
		}
	}

	editCampaign(id) {
		this.props.history.push(`/campaign/${id}`);
	}

	addCampaign() {
		this.props.history.push("/campaign/_add");
	}

	componentDidMount() {
		this.CampaignServiceProvider.getCampaigns().then((res) => {
			this.setState({ campaigns: res.data, loading: false });
		});
	}

	render() {
		const { loading } = this.state;
		return (
			<div>
				<h2 className="text-center">Campaigns</h2>
				<div className="row">
					<button className="btn btn-primary" onClick={this.addCampaign}>
						{" "}
						Add Campaign
					</button>
				</div>
				<br></br>
				{loading && <LoadingSpinner />}
				{!loading && (
					<div className="row">
						<table className="table table-striped table-bordered">
							<thead>
								<tr>
									<th>Campaign Name</th>
									<th style={{ width: "15%" }}></th>
								</tr>
							</thead>
							<tbody>
								{this.state.campaigns.map((campaign) => (
									<tr key={campaign.id}>
										<td>{campaign.name}</td>
										<td>
											<button onClick={() => this.editCampaign(campaign.id)} className="btn btn-info">
												Edit{" "}
											</button>
											<button style={{ marginLeft: "10px" }} onClick={() => this.deleteCampaign(campaign.id)} className="btn btn-danger">
												Delete{" "}
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		);
	}
}
