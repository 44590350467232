import authService from "../components/api-authorization/AuthorizeService";
import axios from "axios";

const BITLY_API_BASE_URL = "https://api-ssl.bitly.com/v4";
const BITLY_AUTH_URL = "/v1/bitly";
const CAMPAIGN_API_BASE_URL = "/v1/campaign";

export class CampaignServiceProvider {
	async createOrUpdateCampaign(campaign) {
		const token = await authService.getAccessToken();
		const user = await authService.getUser();
		campaign.CreatedByUserId = user.sub;
		const json = JSON.stringify(campaign);
		return axios.post(CAMPAIGN_API_BASE_URL + "/createOrUpdateCampaign", json, {
			headers: !token
				? {}
				: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
				  },
		});
	}

	async getCampaigns() {
		const token = await authService.getAccessToken();
		return axios.get(CAMPAIGN_API_BASE_URL, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
	}

	async getCampaignById(campaignId) {
		const token = await authService.getAccessToken();
		return axios.get(CAMPAIGN_API_BASE_URL + "/" + campaignId, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
	}

	async getCampaignStatuses() {
		const token = await authService.getAccessToken();
		return axios.get(CAMPAIGN_API_BASE_URL + "/getAllCampaignStatuses", {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
	}

	async generateBitlyLink(baseProductUrl, baseTargetUrl, campaignId, adLinkTypeId) {
		const token = await authService.getAccessToken();
		const user = await authService.getUser();
		const linkJson = JSON.stringify({
			AdLinkTypeId: adLinkTypeId,
			BaseProductUrl: baseProductUrl,
			BaseTargetUrl: baseTargetUrl,
			CampaignId: campaignId,
		});
		const result = await axios.post(BITLY_AUTH_URL, linkJson, {
			headers: !token
				? {}
				: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
				  },
		});
		const json = JSON.stringify({
			long_url: result.data.targetUrl,
		});
		const bitlyResult = await axios.post(BITLY_API_BASE_URL + "/bitlinks", json, {
			headers: {
				Authorization: `Bearer ${result.data.authToken}`,
				"Content-Type": "application/json",
			},
		});
		return {
			user: user,
			result: bitlyResult,
			product_url: result.data.productUrl,
			target_url: result.data.targetUrl,
		};
	}
	async updateCampaign(campaign) {
		const token = await authService.getAccessToken();
		const json = JSON.stringify(campaign);
		return axios.post(CAMPAIGN_API_BASE_URL + "/update", json, {
			headers: !token
				? {}
				: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
				  },
		});
	}
}
